<template>
  <div class="dashboard-box">
    <el-row class="shortcuts" :gutter="15" v-if="isStockManage">
      <el-col :xs="24">
        <el-button class="ware-btn mrgl1 mrgb10" size="large" plain @click="_select()"><i class="el-icon-s-operation"></i> {{ warehouseName }}</el-button>
        <el-button type="primary" @click="_stock()">查看库存列表</el-button>
      </el-col>
      <el-col class="mrgb20" :xs="12" :sm="8" :lg="4" v-for="(item, index) in defaultTabs" :key="'card' + index">
        <el-card shadow="hover">
          <template #header>{{ item.name }}</template>
          <el-row :gutter="15">
            <el-col class="mrgb10" :xs="24" v-if="tranceAudit(item.audit)">
              <div v-if="index === 0">
                <el-button class="in-btn fl" type="primary" plain @click="_add(item, stockTypeArr[0])">采购{{ item.name }}</el-button>
                <el-button class="in-btn fr" type="primary" plain @click="_add(item)">常规{{ item.name }}</el-button>
                <div class="clearb"></div>
              </div>
              <div v-else-if="index === 2">
                <el-button class="in-btn fl" type="primary" plain @click="_add(item, stockTypeArr[1], true)">{{ item.name }}入库</el-button>
                <el-button class="in-btn fr" type="primary" plain @click="_add(item, null, true)">{{ item.name }}出库</el-button>
                <div class="clearb"></div>
              </div>
              <template v-else>
                <el-button type="primary" plain @click="_add(item)">{{ item.name }}</el-button>
              </template>
            </el-col>
            <el-col class="mrgb10" :xs="24" v-if="tranceAudit(item.audit)">
              <el-button type="primary" plain @click="_opera(item, index)">{{ item.name }}记录</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div class="dashboard-container" v-else>
      <div class="dashboard-text">{{ name }}，{{ appSiteName }}</div>
    </div>

    <el-dialog custom-class="c-el-dialog dashboard-bill-dialog" :visible.sync="showDialog" :before-close="_close">
        <div>
          <all-bill v-if="dialogType === -1" :ref="refStockList"></all-bill>
          <in-bill v-if="dialogType === 0" :ref="'refDashboard' + defaultTabs[0].value"></in-bill>
          <out-bill v-if="dialogType === 1" :ref="'refDashboard' + defaultTabs[1].value"></out-bill>
          <exchange-bill v-if="dialogType === 2" :ref="'refDashboard' + defaultTabs[2].value"></exchange-bill>
          <transfer-bill v-if="dialogType === 3" :ref="'refDashboard' + defaultTabs[3].value"></transfer-bill>
          <damage-bill v-if="dialogType === 4" :ref="'refDashboard' + defaultTabs[4].value"></damage-bill>
        </div>
        <span slot="footer">
            <el-button size="small" @click="_close()">关闭</el-button>
        </span>
    </el-dialog>

    <!--事业部和仓库选择-->
    <condition :ref="refCondition" @refreshData="getCondition()"></condition>

    <!--入库-->
    <in-stock :ref="defaultTabs[0].refStock"></in-stock>
    <!--出库-->
    <out-stock :ref="defaultTabs[1].refStock"></out-stock>
    <!--更换-->
    <exchange-stock :ref="defaultTabs[2].refStock"></exchange-stock>
    <!--调库-->
    <transfer-stock :ref="defaultTabs[3].refStock"></transfer-stock>
    <!--报废-->
    <damage-stock :ref="defaultTabs[4].refStock"></damage-stock>
    <!--更换入库-->
    <exchange-stock-in :ref="refStockExchangeIn"></exchange-stock-in>
  </div>
</template>

<script>
import permission from "@/mixins/permission"
import { mapGetters } from 'vuex'
import { getStockCompany, delStockCompany, getStockWarehourse, delStockWarehourse } from "@/utils/storages"
import * as funInfomation from "@/api/information"
import Condition from "@/views/admin/stock/components/condition"
import InStock from "@/views/admin/stock/components/stockIn"
import OutStock from "@/views/admin/stock/components/stockOut"
import TransferStock from "@/views/admin/stock/components/stockTransfer"
import ExchangeStock from "@/views/admin/stock/components/stockExchange"
import ExchangeStockIn from "@/views/admin/stock/components/stockExchangeIn"
import DamageStock from "@/views/admin/stock/components/stockDamage"
import AllBill from "@/views/admin/stock/allStockList"
import InBill from "@/views/admin/stock/inStockBill"
import OutBill from "@/views/admin/stock/outStockBill"
import TransferBill from "@/views/admin/stock/transferStockBill"
import ExchangeBill from "@/views/admin/stock/exchangeBill"
import DamageBill from "@/views/admin/stock/damageBill"

export default {
  name: 'dashboard',
  mixins: [permission],
  components: { Condition, InStock, OutStock, TransferStock, ExchangeStock, ExchangeStockIn, DamageStock, AllBill, InBill, OutBill, TransferBill, ExchangeBill, DamageBill },
  data() {
    return {
      refStockList: "refDashboardToAllStockList",
      refCondition: "refDashboardToCondition",
      refStockExchangeIn: "refDashboardToStockExchangeIn",
      wareParams: {
        companyId: '',
        ownerId: ''
      },
      allWarehouse: [],
      warehouseName: "切换仓库",
      tabIndex: 0,
      defaultTabs: [
        { name: "入库", value: "In", audit: "isStockInbound", refStock: "refDashboardToInStock" },
        { name: "出库", value: "Out", audit: "isStockOutbound", refStock: "refDashboardToOutStock" },
        { name: "换电", value: "Exchange", audit: "isStockExchange", refStock: "refDashboardToExchangeStock" },
        { name: "调库", value: "Transfer", audit: "isStockTransfer", refStock: "refDashboardToTransferStock" },
        { name: "报废", value: "Damage", audit: "isStockDamage", refStock: "refDashboardToDamageStock" }
      ],
      dialogType: null,
      showDialog: false,
      stockTypeArr: ["BuyIn", "ExchangeIn"]
    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },
  async mounted() {
    await this.getAllWarehouse()
    this.initLoad()
  },
  methods: {
    initLoad() {
        let back = this.getCondition()
        if (!back) {
            this._select()
        }
        return back
    },
    async getAllWarehouse() {
        await funInfomation.searchWarehouse({ companyId: this.wareParams.companyId }).then(res => {
            this.allWarehouse = res.items
        })
    },
    getCondition() {
        let back = false
        let companyItem = getStockCompany()
        let warehourseItem = getStockWarehourse()
        if (warehourseItem && warehourseItem.id) {
            let curWarehouse = this.allWarehouse.find(x => {
                return x.id === warehourseItem.id
            })
            if (curWarehouse) {
              this.warehouseName = curWarehouse && curWarehouse.name ? `${curWarehouse.name} (切换)` : "切换仓库"
            } else {
              this.warehouseName = "切换仓库"
              delStockCompany()
              delStockWarehourse()
            }
        } else {
            this.warehouseName = "切换仓库"
            delStockCompany()
            delStockWarehourse()
        }
        this.wareParams.companyId = companyItem && companyItem.id || ""
        this.wareParams.ownerId = warehourseItem && warehourseItem.id || ""
        if (this.wareParams.companyId && this.wareParams.ownerId) {
            back = true
        }
        return back
    },
    _select() {
        this.$refs[this.refCondition].open()
    },
    _add(item, typeName, isBattery) {
      if (!this.initLoad()) {
        return false
      }
      // 需要传type
      if (typeName) {
        let params = {
          type: typeName
        }
        if (typeName === this.stockTypeArr[1]) {
          // 更换入库
          if (isBattery) {
            // 电池更换入库
            this.$refs[this.refStockExchangeIn].open()
          } else {
            // 一般更换入库
          }
        } else {
          // 采购入库
          this.$refs[item.refStock].open(params)
        }
      } else {
        this.$refs[item.refStock].open()
      }
    },
    _stock() {
      if (!this.initLoad()) {
        return false
      }
      this.dialogType = -1
      this.showDialog = true
      setTimeout(() => {
        this.$refs[this.refStockList].open()
      }, 10)
    },
    _opera(item, index) {
      if (!this.initLoad()) {
        return false
      }
      this.dialogType = index
      this.showDialog = true
      setTimeout(() => {
        this.$refs[`refDashboard${item.value}`].open()
      }, 10)
    },
    tranceAudit(audit) {
      let back = false
      if (this[audit]) {
        back = true
      }
      return back
    },
    _close() {
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  &-box {
    width: 100%;
    height: 100%;
    min-height: 650px;
    padding:10px 0;
  }
  &-container {
    width: 100%;
    height: 100%;
    min-height: 650px;
    margin: 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    background: #f1f2f3;
    display: table;
  }
  &-text {
    width: 100%;
    font-size: 30px;
    line-height: 46px;
    display: table-cell;
    vertical-align: middle;
  }
}
/deep/ .dashboard-bill-dialog {
  margin-top: 6vh !important;
  max-width: 92%;
}
</style>
<style lang="scss">
.shortcuts {
  .el-button {
    font-size: 15px;
  }
  .el-card__body {
    height: 92px;
    padding: 20px 10px;
  }
  .el-card {
    .el-card__header {
      font-size: 16px;
    }
    .el-button {
      display: block;
      margin: 0 auto;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .el-button.in-btn {
      display: inline-block;
      width: 48%;
    }
  }
  @media screen and (max-width: 768px) {
    .el-card__body {
      height: 140px;
    }
    .el-card {
      .el-button.in-btn {
        width: 100%;
      }
      .el-button.in-btn.fr {
        margin-top: 10px;
      }
    }
  }
}
</style>
