<style lang="scss" scoped>
/deep/ .serials-drawer {
    .el-drawer__header {
        margin-bottom: 10px;
    }
    .el-drawer__body {
        padding: 0 12px;
    }
    .pagination-container {
        text-align: right;
        margin-top: 10px;
    }
}
</style>

<template>
    <div>
        <el-drawer :title="drawerTitle"  custom-class="serials-drawer" :size="$root.isPc ? '60%' : '100%'" :visible.sync="showDrawer" direction="rtl" 
            :append-to-body="true" :destroy-on-close="true">
            <div class="mrgb10" v-if="listQuery.assetName">【{{ listQuery.assetName }}】</div>
            <el-table :data="tableData" style="width: 100%" border size="medium">
                <el-table-column label="序列号" prop="id"></el-table-column>
                <el-table-column label="拥有者类型【名称】" min-width="80px">
                    <template slot-scope="scope">
                        {{ tranceOwnerType(scope.row) }}<span class="col_primary" v-if="tranceOwnerName(scope.row)">【{{ tranceOwnerName(scope.row) }}】</span>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    :layout="$root.isPc ? 'total, sizes, prev, pager, next, jumper' : 'pager'" :total="total">
                </el-pagination>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "compSerials",
    mixins: [enumConfigs],
    data() {
        return {
            showDrawer: false,
            drawerTitle: "序列号",
            tableData: [],
            total: null,
            listQuery: {
                companyId: "",
                assetId: "",
                assetName: "",
                ownerType: null,
                ownerId: null,
                keywords: "",
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            allWarehouse: [],
            allAssetUser: []
        }
    },
    methods: {
        async open(params) {
            for (let i in this.listQuery) {
                this.listQuery[i] = params[i] || this.listQuery[i]
            }
            await this.initData()
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        async initData() {
            await this.getAllWarehouse()
            await this.getAllAssetUser()
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funStock.getSerialNumberDatas(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                    this.showDrawer = true
                } else {
                    this.tableData = []
                    this.warningMsg("该资产暂未绑定序列号！")
                }
            })
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser({ companyId: this.listQuery.companyId }).then(res => {
                this.allAssetUser = res.items
            })
        },
        // 转换类型
        tranceOwnerType(row) {
            let temp = this.arrAssetOwnerType.find(x => x.value === row.ownerType)
            return temp && temp.desc || ""
        },
        // 所属仓库
        tranceOwnerName(row) {
            let temp = {}
            if (row.ownerType === this.valOwnerWarehouse()) {
                temp = this.allWarehouse.find(x => {
                    return row.ownerId === x.id
                })
            } else if (row.ownerType === this.valOwnerAssetUser()) {
                temp = this.allAssetUser.find(x => {
                    return row.ownerId === x.id
                })
            }
            return temp && temp.name || ""
        }
    }
}
</script>