<style lang="scss" scoped>
/deep/ .comp-damagestock {
    margin-top: 6vh !important;
    max-width: 1200px;
    .item-card {
        position: relative;
        width: 100%;
        height: auto;
        margin: 15px auto 0;
        padding-bottom: 35px;
        background-color: #f5f5f5;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden;
        .image {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .count {
            padding: 0 5px;
            .el-input-number--mini {
                width: 100%;
            }
        }
        .title {
            position: relative;
            height: 60px;
            padding: 0 4px;
            line-height: 1.6;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
        .buttons {
            width: 92%;
            position: absolute;
            bottom: 5px;
            left:4%;
            .el-button--mini.is-circle {
                padding: 4px;
            }
            .btn {
                padding: 5px;
            }
        }
        .el-image {
            width: 100%;
            aspect-ratio: 1.2;
            .image-slot {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .el-input-number--mini .el-input-number__decrease, 
        .el-input-number--mini .el-input-number__increase {
            width: 20px;
        }
    }
}
.assets {
    font-size: 13px;
    border-bottom: 1px dashed #eee;
    padding: 5px 0;
    .fl {
        max-width: 96%;
    }
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .asset-title {
        vertical-align: middle;
        line-height: 1.5;
        white-space: normal;
        display: inline-block;
    }
}
</style>

<template>
    <div>
        <el-dialog :title="title" custom-class="c-el-dialog comp-damagestock" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" append-to-body>
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="15" v-if="false">
                        <el-form-item label="仓库" prop="warehouseId">
                            <el-select class="c-pw100 mrgr5 mrgb5" placeholder="选择出库的仓库" filterable
                                clearable v-model="dialogData.warehouseId" disabled>
                                <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="">
                            <el-row class="mrgb10">
                                <el-button type="primary" :plain="scanCodeType !== 1" @click="scanBarcode()"><i class="fa fa-barcode"></i> 扫条形码</el-button>
                                <el-button type="primary" :plain="scanCodeType !== 2" @click="scanQrCode()"><i class="fa fa-qrcode"></i> 扫二维码</el-button>
                            </el-row>
                            <el-row>
                                <el-autocomplete :ref="refAutoComplete" class="c-pw100" v-model="keyword" :trigger-on-focus="false" clearable :fetch-suggestions="querySearchAsync" 
                                    placeholder="资产名称/条形码" @select="handleSelect" @keyup.enter.native="execSelect()" @clear="clearAutoComplete">
                                    <template slot-scope="{ item }">
                                        <dl class="assets">
                                            <dd class="fl">
                                                <el-image class="asset-image" :src="uploadBaseUrl + item.imageUrl">
                                                    <div slot="error">
                                                        <i class="el-icon-picture-outline error-image"></i>
                                                    </div>
                                                </el-image>
                                                <span class="asset-title">
                                                    <span>{{ mxTranceAssetData(item) }}</span>
                                                    <span :class="mxTranceAssetData(item) ? '' : 'mrgl10'">{{ item.value }}</span>
                                                    <span class="mrgl5 col_danger" v-if="item.maxCount >= 0 && !$root.isPc">【库存：{{ item.maxCount }}】</span>
                                                </span>
                                            </dd>
                                            <dd class="fr col_danger" v-if="item.maxCount >= 0 && $root.isPc">【库存：{{ item.maxCount }}】</dd>
                                            <div class="clearb"></div>
                                        </dl>
                                    </template>
                                </el-autocomplete>
                            </el-row>
                            <el-row :gutter="10">
                                <template v-for="(item, index) in dialogData.items">
                                    <el-col :xs="8" :sm="6" :md="4" :lg="4" :key="'ItemCard' + index">
                                        <div class="item-card">
                                            <el-image :src="uploadBaseUrl + item.imageUrl" :preview-src-list="[uploadBaseUrl + item.imageUrl]" fit="cover">
                                                <div slot="error" class="image-slot">
                                                    <img src="/static/images/icons/image-failed.png" />
                                                </div>
                                            </el-image>
                                            <div class="title">{{ item.assetName }}</div>
                                            <div class="count">
                                                <el-input-number v-model="item.count" :max="item.maxCount" :min="0" v-if="item.maxCount && item.maxCount > 0"
                                                    :disabled="item.needSerialNumber" size="mini"></el-input-number>
                                                <el-input-number v-model="item.count" :min="0" v-else
                                                    :disabled="item.needSerialNumber" size="mini"></el-input-number>
                                            </div>
                                            <div class="buttons">
                                                <el-button class="btn fl" size="mini" type="primary" icon="el-icon-edit" 
                                                    v-if="item.needSerialNumber" @click="openScanCode(item)">序列号</el-button>
                                                <el-button class="btn fr" size="mini" type="danger" icon="el-icon-delete"
                                                    @click="_delete(item, index)"></el-button>
                                                <div class="clearb"></div>
                                            </div>
                                        </div>
                                    </el-col>
                                </template>
                            </el-row>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="15">
                        <el-form-item label="负责人" prop="principal">
                            <el-select class="c-pw100" v-model="dialogData.principal" filterable placeholder="请选择负责人"
                                :default-first-option="true">
                                <el-option v-for="item in allPrincipal" :value="item.name" :key="item.id">
                                    <span class="fl">
                                        {{ item.name }}
                                        <span class="col_info mrgl5" v-if="item.phone">{{ item.phone }}</span>
                                    </span>
                                    <span class="fr col_info">{{ item.company && item.company.name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="附件" prop="attachments">
                            <multiple-images :imageUrls="dialogData.attachments" :folder="'damagestock'" :multiple="true" @emitImageUrls="setImageUrls(arguments)"></multiple-images>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" :rows="3" v-model="dialogData.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button @click="_close()">取消</el-button>
                <el-button type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>

        <!--扫码getScanSerials-->
        <scan-serial :ref="refToScanSerial" @scanSerials="setAssetDatas"></scan-serial>
        <!--调用摄像头扫码-->
        <html-scan :ref="refHtmlScan" @success="htmlScanSuccess(arguments)"></html-scan>
    </div>
</template>

<script>
import stockCommon from "./js/stockCommon"
import MultipleImages from "@/components/ImageMultipleUpload"
import ScanSerial from "./scanSerial"
import HtmlScan from "@/components/HtmlScan"
import { getStockCompany, getStockWarehourse } from "@/utils/storages"
import * as funCommon from "@/api/common"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "compStockDamage",
    mixins: [stockCommon],
    components: { MultipleImages, ScanSerial, HtmlScan },
    data() {
        return {
            refToScanSerial: "refDamageStockToScanSerial",
            refAutoComplete: "refDamageStockToAutoComplete",
            refHtmlScan: "refDamageStockToHtmlScan",
            showDialog: false,
            companyItem: {},
            warehourseItem: {},
            dialogData: {},
            defaultDialogData: {
                companyId: "",
                warehouseId: "",
                principal: "",
                remark: "",
                items: [],
                attachments: []
            },
            dialogType: "",
            formRefName: "refDamageStock",
            formRules: {
                warehouseId: [{ required: true, message: '请选择仓库', trigger: ['blur', 'change']}],
                principal: [{ required: true, message: '请选择负责人', trigger: ['blur', 'change'] }]
            },
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            },
            allPrincipal: [],
            keyword: "",
            title: "",
            scanCodeType: 1
        }
    },
    methods: {
        async open(row) {
            this.title = "报废"
            this.scanCodeType = 1
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            // 默认仓库
            this.companyItem = getStockCompany()
            this.warehourseItem = getStockWarehourse()
            this.dialogData.companyId = this.companyItem && this.companyItem.id || ""
            this.dialogData.warehouseId = this.warehourseItem && this.warehourseItem.id || ""
            if (this.warehourseItem && this.warehourseItem.id) {
                this.title = `报废【${this.companyItem.name}-${this.warehourseItem.name}】`
            }
            await this.getAllPrincipal()
            this.showDialog = true
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    // 验证资产
                    if (this.dialogData.items.length <= 0) {
                        this.errorMsg(`请添加需要报废的资产。`)
                        return false
                    }
                    // 验证上传的数量
                    let warnTemp = undefined
                    for ( let i in this.dialogData.items) {
                        if (this.dialogData.items[i].count <= 0) {
                            warnTemp = this.dialogData.items[i]
                            break
                        }
                    }
                    if (warnTemp && warnTemp.count <= 0) {
                        this.errorMsg(`${warnTemp.assetName}，报废数量必须大于0，否则请删除!`)
                        return false
                    }
                    let commitData = {
                        warehouseId: this.dialogData.warehouseId,
                        principal: this.dialogData.principal,
                        remark: this.dialogData.remark,
                        attachments: this.dialogData.attachments || [],
                        items: this.dialogData.items.map(x => {
                            let temp = { 
                                assetId: x.assetId, 
                                count: x.count, 
                                serialNumbers: x.serialNumbers 
                            }
                            return temp
                        }) || []
                    }
                    window.$common.fullLoading()
                    funStock.damageStock(commitData).then((res) => {
                        window.$common.closeFullLoading()
                        this.commonSuccess("操作成功!", res)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>